define("discourse/plugins/stemaway-ui-addons/discourse/components/sidebar-aivia", ["exports", "@glimmer/component", "@ember/service"], function (_exports, _component, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SidebarAiviaComponent extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.inject]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    transitionTo = routeName => {
      if (routeName) {
        this.router.transitionTo(routeName);
      } else {
        console.error("Route name is undefined");
      }
    };
  }
  _exports.default = SidebarAiviaComponent;
});