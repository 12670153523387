define("discourse/plugins/stemaway-ui-addons/discourse/helpers/eq", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _helper.helper)(function eq(_ref) {
    let [a, b] = _ref;
    return a === b;
  });
});