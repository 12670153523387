define("discourse/plugins/stemaway-ui-addons/discourse/templates/components/sidebar-aivia", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="sidebar-aivia-container">
    <div class="sidebar-content">
      <ul>
        <li class="sidebar-item">
          <a href="#" {{on "click" (fn this.transitionTo "aivia-analytics")}}>Overview</a>
        </li>
        <li class="sidebar-item">
          <a href="#" {{on "click" (fn this.transitionTo "aivia-analytics.performance")}}>Performance</a>
        </li>
        <li class="sidebar-item">
          <a href="#" {{on "click" (fn this.transitionTo "aivia-analytics.scout")}}>Post & Scout</a>
        </li>
        <li class="sidebar-item">
          <a href="#" {{on "click" (fn this.transitionTo "aivia-analytics.scout")}}>Custom Prescreening</a>
        </li>
      </ul>
    </div>
  </div>
  
  */
  {
    "id": "8/ofspio",
    "block": "[[[10,0],[14,0,\"sidebar-aivia-container\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"sidebar-content\"],[12],[1,\"\\n    \"],[10,\"ul\"],[12],[1,\"\\n      \"],[10,\"li\"],[14,0,\"sidebar-item\"],[12],[1,\"\\n        \"],[11,3],[24,6,\"#\"],[4,[38,4],[\"click\",[28,[37,5],[[30,0,[\"transitionTo\"]],\"aivia-analytics\"],null]],null],[12],[1,\"Overview\"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,\"li\"],[14,0,\"sidebar-item\"],[12],[1,\"\\n        \"],[11,3],[24,6,\"#\"],[4,[38,4],[\"click\",[28,[37,5],[[30,0,[\"transitionTo\"]],\"aivia-analytics.performance\"],null]],null],[12],[1,\"Performance\"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,\"li\"],[14,0,\"sidebar-item\"],[12],[1,\"\\n        \"],[11,3],[24,6,\"#\"],[4,[38,4],[\"click\",[28,[37,5],[[30,0,[\"transitionTo\"]],\"aivia-analytics.scout\"],null]],null],[12],[1,\"Post & Scout\"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,\"li\"],[14,0,\"sidebar-item\"],[12],[1,\"\\n        \"],[11,3],[24,6,\"#\"],[4,[38,4],[\"click\",[28,[37,5],[[30,0,[\"transitionTo\"]],\"aivia-analytics.scout\"],null]],null],[12],[1,\"Custom Prescreening\"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"div\",\"ul\",\"li\",\"a\",\"on\",\"fn\"]]",
    "moduleName": "discourse/plugins/stemaway-ui-addons/discourse/templates/components/sidebar-aivia.hbs",
    "isStrictMode": false
  });
});