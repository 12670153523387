define("discourse/plugins/stemaway-ui-addons/discourse/helpers/fix-emoji", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.renderEmojis = renderEmojis;
  function renderEmojis(_ref) {
    let [value] = _ref;
    if (!value) return '';
    return value.replace(/:red_circle:/g, '🔴').replace(/:yellow_circle:/g, '🟡').replace(/:green_circle:/g, '🟢');
  }
  var _default = _exports.default = (0, _helper.helper)(renderEmojis);
});